import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@components/NotFound'

import globals from '@/globals'

import auth from './auth'
import publicView from './public'
import user from './user'
import admin from './admin'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  { path: '', redirect: '/home' }
]
  .concat(publicView)
  .concat(auth)
  .concat(user)
  .concat(admin)

// 404 Not Found page
ROUTES.push({
  path: '*',
  component: NotFound
})

const router = new Router({
  base: '/',
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  routes: ROUTES
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
